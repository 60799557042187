function ChatMessageItemReply({
  name,
  message,
  goToReplyMessage,
  lang,
  messageTypeFunction,
}) {
  const {
    imageLoaded,
    onImageLoaded,
    reloadSticker,
    displayText,
    viewImage,
    downloadFile,
    downloadIcon,
    fileIcon,
  } = messageTypeFunction;

  function fileSizeFilter(size) {
    if (isNaN(size)) size = 0;

    if (size < 1024) return `${size} Bytes`;

    size /= 1024;

    if (size < 1024) return `${size.toFixed(2)} KB`;

    size /= 1024;

    if (size < 1024) return `${size.toFixed(2)} MB`;

    size /= 1024;

    if (size < 1024) return `${size.toFixed(2)} GB`;

    size /= 1024;

    return `${size.toFixed(2)} TB`;
  }

  return (
    <>
      <div
        style={{
          paddingBottom: "5px",
          marginLeft: "6px",
          fontSize: "12px",
          color: "#8392AF",
        }}
      >
        <i class="icon icon-fluent_arrow-reply" style={{ margin: "unset" }}></i>
        <span>
          {message.reply_to.sender !== message.sender
            ? lang.__replied_to_you
            : lang.__replied_themselves}
        </span>
      </div>
      <div
        className="msg"
        style={{
          whiteSpace: "unset",
          minWidth: message.reply_to.type !== "text" ? "160px" : "unset",
        }}
      >
        <div
          onClick={() =>
            ["file", "image", "sticker", "text", "flex"].includes(
              message.reply_to.type
            ) &&
            goToReplyMessage(
              message.time,
              message.reply_to.time,
              message.reply_to.type,
              message.reply_to.imageIndex
            )
          }
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid #CEDAEE",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: ["file", "image", "sticker", "text", "flex"].includes(
              message.reply_to.type
            )
              ? "pointer"
              : "default",
          }}
        >
          {message.reply_to.is_deleted ? (
            <span className="reply-to sp-text-help">
              {`${name} ${lang.__unsent_message_no_name}`}
            </span>
          ) : (
            <>
              {message.reply_to.type === "text" && (
                <span className="reply-to sp-text-help">
                  {message.reply_to.is_deleted
                    ? `${name} ${lang.__unsent_message_no_name}`
                    : message.reply_to.text}
                </span>
              )}
              {message.reply_to.type !== "text" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    className="reply-to sp-text-help -ellipsis"
                    style={{
                      alignContent: "center",
                      paddingRight: "10px",
                      maxWidth:
                        message.reply_to.type === "file" ? "200px" : "unset",
                    }}
                  >
                    {message.reply_to.type === "image"
                      ? lang.__photo
                      : message.reply_to.type === "sticker"
                      ? lang.__send_A_Sticker
                      : message.reply_to.type === "file"
                      ? `${lang.__file}: ${message.reply_to.metadata.name}`
                      : message.reply_to.type === "flex"
                      ? `${lang.__flex_message}: ${message.reply_to.altText}`
                      : lang.__message_unavailable}
                  </span>
                  {message.reply_to.type === "file" && (
                    <p className="sp-text-help" style={{ fontSize: "12px" }}>
                      <span>{lang.__send_a_file_size}</span>{" "}
                      <span>
                        {fileSizeFilter(message.reply_to.metadata.size)}
                      </span>
                    </p>
                  )}
                </div>
              )}
              {message.reply_to.type === "file" && (
                <img
                  style={{ width: "28px", maxHeight: "60px", opacity: "0.2" }}
                  src={`/public/img/${message.reply_to.metadata.icon}.png`}
                  alt="file icon"
                />
              )}
              {message.reply_to.type === "image" && !imageLoaded && (
                <span
                  className="msg-image image_reply loading"
                  style={{ width: "unset", height: "unset" }}
                >
                  <div className="sp-placeholder">
                    <div className="sp-text-help">
                      <i
                        className="icon icon-circle-notch animate-spin"
                        style={{ margin: "unset" }}
                      ></i>
                    </div>
                  </div>
                </span>
              )}
              {message.reply_to.type === "image" && imageLoaded && (
                <img
                  id="imagesave"
                  className="image_reply"
                  src={
                    message.reply_to.amount_image > 1
                      ? message.reply_to.img_urls[message.reply_to.imageIndex]
                      : message.reply_to.img_url ||
                        message.reply_to.img_url_full ||
                        message.reply_to.image
                  }
                  alt="reply image"
                />
              )}
              {message.reply_to.type === "sticker" &&
                (message.reply_to.facebook_sticker_url ? (
                  <img
                    id="imagesave"
                    className="image_reply"
                    style={{ objectFit: "contain" }}
                    src={message.reply_to.facebook_sticker_url}
                    alt="sticker"
                  />
                ) : (
                  <img
                    id="imagesave"
                    className="image_reply"
                    style={{ objectFit: "contain" }}
                    src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message?.reply_to?.line_sticker_id}/iPhone/sticker.png`}
                    alt="sticker"
                    onError={(e) => {
                      e.target.src = `https://stickershop.line-scdn.net/stickershop/v1/sticker/${message?.reply_to?.line_sticker_id}/android/sticker.png`;
                    }}
                  />
                ))}
            </>
          )}
        </div>
        {message.type === "sticker" && imageLoaded && (
          <div className="msg-image" style={{ paddingTop: "10px" }}>
            <a
              href={message.img_url_full}
              target="__blank"
              rel="noopener noreferrer"
            >
              <img
                className="image sticker"
                style={{ maxWidth: "200px" }}
                src={message.sticker_url}
                onClick={reloadSticker}
                onLoad={onImageLoaded}
                alt="sticker"
              />
            </a>
          </div>
        )}
        {(message.type === "sticker" || message.type === "image") &&
          !imageLoaded && (
            <div
              className="msg-image loading"
              style={{
                marginTop: "10px",
                marginLeft: "unset",
                maxWidth: "unset",
              }}
            >
              <div className="sp-placeholder">
                <div className="sp-text-help">
                  <i className="icon icon-circle-notch animate-spin"></i>{" "}
                  <span>{lang.__loading}</span>
                </div>
              </div>
            </div>
          )}
        {message.type === "image" && imageLoaded ? (
          message.amount_image ? (
            <div
              className="msg-image-multiple"
              style={{ paddingTop: "10px", margin: "unset" }}
            >
              {message.img_urls.map((item, index) => (
                <div
                  key={index}
                  className="msg-image"
                  style={{ backgroundImage: `url(${item})` }}
                  onClick={() => viewImage(index)}
                ></div>
              ))}
            </div>
          ) : (
            <div
              className="msg-image"
              style={{ paddingTop: "10px", margin: "unset", maxWidth: "100%" }}
            >
              <img
                className="image"
                style={{ maxWidth: "100%" }}
                src={message.image || message.img_url || message.img_url_full}
                onLoad={onImageLoaded}
                onClick={() => viewImage()}
                alt="message"
              />
              {message.image_thumbnail && (
                <img
                  className="image"
                  style={{ maxWidth: "100%" }}
                  src={message.image_thumbnail}
                  onLoad={onImageLoaded}
                  onClick={() => viewImage()}
                  alt="thumbnail"
                />
              )}
            </div>
          )
        ) : null}
        {message.type === "text" && (
          <div
            style={{ paddingTop: "10px" }}
            dangerouslySetInnerHTML={{ __html: displayText }}
          ></div>
        )}
        {message.type === "file" && (
          <div
            className="file-download"
            style={{
              minWidth: "200px",
              maxWidth: "100%",
              marginLeft: "unset",
              marginTop: "10px",
              borderRadius: "12px",
            }}
          >
            <div
              className="sp-placeholder"
              style={{
                display: message.state === "sending" ? "block" : "none",
              }}
            >
              <div className="sp-text-help">
                <i className="icon icon-circle-notch animate-spin"></i>
              </div>
            </div>
            <div
              className="file-info"
              style={{
                display:
                  message.state === "finish" || message.state === "error"
                    ? "flex"
                    : "none",
              }}
            >
              <img
                className="file-info-img"
                src={`../public/img/${fileIcon}.png`}
                alt="file icon"
              />
              <div className="file-info-text">
                <p className="sp-text">{message.metadata.name}</p>
                <p className="sp-text-help">
                  <span>{lang.__send_a_file_size}</span>{" "}
                  <span>{fileSizeFilter(message.metadata.size)}</span>
                </p>
              </div>
            </div>
            <div
              className="separator"
              style={{ display: message.state === "finish" ? "flex" : "none" }}
            ></div>
            <div
              className="sp-btn -blue"
              onClick={() => downloadFile(message.file_url)}
              style={{ display: message.state === "finish" ? "flex" : "none" }}
            >
              <img
                className="icon-download"
                src={downloadIcon}
                alt="download icon"
              />
              <span>{lang.__download}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
